<template>
  <div class="Category">
    <v-card class="mx-auto" max-width="1170">
      <v-toolbar color="orange" dark>
        <v-toolbar-title>製品詳細と関連情報</v-toolbar-title>
      </v-toolbar>
      <p>{{ product.name }}の詳細情報です。</p>
      <v-list>
        <v-list-item-group>
          <v-list-item :to="{ name: 'Top' }">
            <v-list-item-content>トップに戻る</v-list-item-content>
          </v-list-item>
          <v-list-item :to="{ name: 'Category' }">
            <v-list-item-content>カテゴリ一覧に戻る</v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-card>
    <v-card class="mx-auto" max-width="1170">
      <v-toolbar color="orange" dark>
        <v-toolbar-title>製品詳細</v-toolbar-title>
      </v-toolbar>
      <template v-if="summary.length === 0">
        <v-list two-line>
          <v-list-item-group>
            <v-list-item>
              <template>
                <v-list-item-content>
                  <v-list-item-title>登録されていません。</v-list-item-title>
                </v-list-item-content>
              </template>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </template>
      <template v-else>
        <v-list-item-group>
          <template>
            <v-list-item v-for="(data,index) in summary" :key="index">
              <template>
                <v-list-item-content>
                  <v-list-item-title v-text="index"></v-list-item-title>
                  <v-list-item-subtitle v-text="data"></v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-list-item>
          </template>
        </v-list-item-group>
      </template>
      <v-toolbar color="orange" dark>
        <v-toolbar-title>関連情報</v-toolbar-title>
      </v-toolbar>
      <template v-if="informations.length === 0">
        <v-list-item-group>
          <template>
            <v-list-item>
              <template>
                <v-list-item-content>
                  <v-list-item-title>登録されていません。</v-list-item-title>
                </v-list-item-content>
              </template>
            </v-list-item>
          </template>
        </v-list-item-group>
      </template>
      <template v-else>
        <v-list-item-group>
          <template>
            <v-list-item v-for="(information, index) in informations" :key="index">
              <template>
                <v-list-item-content>
                  <v-list-item-title v-text="information.title"></v-list-item-title>
                  <v-list-item-subtitle v-text="information.url"></v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-list-item>
          </template>
        </v-list-item-group>
      </template>
    </v-card>
  </div>
</template>


<script>
const axios = require('axios')

export default{
  name: 'Product',
  components: {
  },
  watch: {
    product: function() {
      this.summary = JSON.parse(this.product.summary)
    }
  },
  data () {
    return {
      product: null,
      summary: null,
      informations: null
    }
  },
  beforeCreate() {
    const productId = this.$route.params.productId
    // @todo product's'って名前を直したい気持ちがある
    axios.get('https://api.pc-combo.tokyo/products/' + productId + '.json')
        .then(
            response => {
              this.product = response.data.products
              document.title = this.product.name + 'の' + document.title
            }
        )
        .catch(error => console.log(error))

    // @todo 次の作業はinformation詳細ページのAPIを作ってリンクを張るところからスタート
    axios.get('https://api.pc-combo.tokyo/informations/' + productId + '.json')
        .then(response => (this.informations = response.data.informations))
        .catch(error => console.log(error))
  }
}
</script>
